import React, { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { toast } from "react-toastify";
import { useContactMessageMutation } from "../slices/contactSlice";

const Contact = ({ handleOpen }) => {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [details, setDetails] = useState("");

  const [ContactMessage] = useContactMessageMutation();

  const sendMessage = async (e) => {
    e.preventDefault();
    try {
      const res = await ContactMessage({ email, subject, details });
      if (res.success) {
        toast.success(
          "Message sent successfully.. We'll get in touch with you shortly"
        );
      }
      setEmail("");
      setSubject("");
      setDetails("");
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };

  return (
    <div className="relative bg-white z-50 text-slate-600">
      <div className="bg-red-500">
      {/* <div className=" w-[98%] md:w-[60%] mx-auto min-h-full absolute top-24 z-50 left-0 right-0 rounded-lg"> */}
        {/* <div className=" dark:bg-[#111] bg-white w-[98%] md:w-[60%] mx-auto min-h-full absolute top-0 z-10 left-0 right-0 rounded-lg"> */}
        <div className="w-5/6 absolute lg:w-4/6 py-3 lg:py-8 mx-auto">
          <div className="flex items-center mb-3 lg:mb-8 py-3 a">
            <button className="cursor-pointer" onClick={handleOpen}>
              <AiOutlineCloseCircle size={20} />{" "}
            </button>
            <div className="mx-3 cursor-pointer text-sm" onClick={handleOpen}>
              Close
            </div>
          </div>
          <div className="text-center text-sm md:text-base">
            <h1 className="mb-4 font-bold">Have a project to discuss? </h1>
          </div>

          <form onSubmit={sendMessage} className="mt-12">
            <div className="mb-1 lg:mb-3">
              <label className="block mb-1 text-sm">Enter your email</label>
              <input
                className="w-full p-3 mb-3 rounded-lg border"
                placeholder="Enter your email address"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-1 lg:mb-3">
              <label className="block mb-1 text-sm ">Subject</label>
              <input
                className="w-full p-3 mb-3 rounded-lg border"
                placeholder="Subject"
                required
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>
            <div className="mb-1 lg:mb-3">
              <label className="block mb-1 text-sm ">Details</label>

              <textarea
                className="w-full h-[120px] p-4 mb-3 rounded-lg border"
                placeholder="Enter message"
                required
                value={details}
                onChange={(e) => setDetails(e.target.value)}
              />
            </div>
            <button
              className="p-3 bg-slate-900 rounded-lg text-white float-right mb-8"
              type="submit"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
