import { apiSlice } from "./apiSlice";
import { CONTACT_URL } from "../constants";

const contactApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    contactMessage: builder.mutation({
      query(body) {
        return {
          url: `${CONTACT_URL}`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Contact"],
    }),
  }),
});

export const { useContactMessageMutation } = contactApiSlice;
