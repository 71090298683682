import React, { useState } from "react";
import Contact from "../components/Contact";
// import CountDown from "../components/CountDown";
import { TbSend } from "react-icons/tb";
import ShiftingCountdown from "../components/CountDown";

const HomeScreen = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className="relative w-full">
      {open && (
        <Contact handleOpen={handleOpen} open={open} setOpen={setOpen} />
      )}
      <div className="flex h-screen items-center justify-center lg:w-full w-11/12 mx-auto">
        <div className="mx-auto text-center">
          <ShiftingCountdown />
          <p className="mt-4">Maintenance in progress...</p>
          <h1 className="font-bold text-4xl lg:text-6xl my-4">
            We Will Be Back Shortly
          </h1>
          <p className="my-8">Need to contact us urgently?</p>
          <a href="mailto:adeomike9@gmail.com"
            className="font-semibold text-base lg:text-lg rounded-lg bg-[#f9bd4c] hover:bg-wheat text-white cursor-pointer py-3 px-5"
          >
            Send a message <TbSend className="inline" />
          </a>
          {/* <button
            className="font-semibold text-base lg:text-lg rounded-lg bg-[#f9bd4c] hover:bg-wheat text-white cursor-pointer py-3 px-5"
            onClick={handleOpen}
          >
            Send a message <TbSend className="inline" />
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default HomeScreen;
